.curso {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin: 20px auto;
    width: 100%;
    max-width: 1140px;
    padding-left: 20px;
  }
  
   .logo_curso {
  //   //margin-left: 10px;
   width: 80px;
   height: 100%;
   justify-content: center;
   
  }
  
  // .nome_curso {
  //   padding: 10px;
  //   margin-left: 5px;
  //   display: flex;
  //   flex-direction: column; 
  //   h2{
  //     text-align: left;
  //   }
  // }
  
  .evolucao {
    //flex-grow: 2;
   width: 30%;
    padding: 10px;
    margin-right: 10px;
    margin-left: 20px;
    p{
      margin: 0px;
    }
  }
