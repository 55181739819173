@import '~bootstrap/scss/bootstrap';
.body{
    overflow-x: hidden!important; 
    margin-right: 0;
    margin-left: 0;
    padding-right:0;
}

.MuiButtonBase-root.add-employee{
    width: 250px;
    padding: 10px;
    border-radius: 5px;
}

.MuiButtonBase-root.button-modal{
    padding: 10px;
    border-radius: 5px;
}
.container-cadastro{
    margin-left: 15px;
}
.modal-custom-width {
    width: 100%; /* Defina a largura desejada aqui */
    margin: 0 auto; /* Centralizar o modal na tela */
}
.form-container {
    display: flex;
    flex-direction: column;
  }
  
  .input-container {
    display: flex;
    justify-content: space-between;
  }
  
  .input-wrapper {
    flex: 1;
    margin-right: 10px; /* Espaçamento entre os inputs */
  }
  