// body {
//     display: flex;
//     flex-direction: column;
//     min-height: 100vh;
//     margin: 0;
// }

.main-content {
    flex: 1;
    /* Estica para ocupar todo o espaço restante */
}

.footer {
    background-color: #413c69;
    color: white;
    padding: 10px 20px;
    text-align: center;
    width: 100%;
    margin-top: auto !important;
}


.footer_logo {
    padding-left: 10px;
    display: flex;
    color: aliceblue;
    margin: auto;
    text-align: center;
    align-items: center;
    justify-content: center;
}

.direitos-fatecoins {
    color: white;
    margin: auto;
    text-align: center;
    align-items: center;
    flex-direction: column;
    justify-content: center;
}