.porcentagem{    
    &-positiva{
        font-size: 2.4em;
        font-weight: bold;
        color: #090;
    }
    &-negativa{
        font-size: 2.4em;
        font-weight: bold;  
        color: #900;    
    }
}
.bold{
    font-weight: bold;
}
p.skill-item{
    margin: 0px;
    margin-left: 25px;
    strong{
        color: #090;
    }
}
.MuiCheckbox-colorSecondary.Mui-checked {
    color: #413c69!important;
}
.MuiRadio-colorSecondary.Mui-checked {
    color: #413c69!important;
}