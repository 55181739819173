@media only screen and (max-device-width: 900px) {
  /* .menu { width:600px; } */

  .container {
    width: 600;
    overflow: hidden;
  }
  .signin_wrapper {
    width: 600;
    overflow: hidden;
  }
}

.valor {
  margin-left: 10px;
  text-align: left;
  p {
    font-size: 1.2em;
    line-height: 1em;
  }
}
.mes {
  width: 50%;
  text-align: left;
  display: inline-block;
  margin-left: 10px;
}
.ano {
  width: 45%;
  text-align: left;
  display: inline-block;
  margin-left: 10px;
}
.cv {
  font-size: 0;
  width: 390px;
  margin-left: 13px;
}
.containerWhite-payments {
  margin-top: "10px";
  border-radius: 10px;
 
  background-color: #ffffff;

}

.container-payment {
  width: 50%;
  max-width: 500px;
  margin: auto;
  margin-top: 80px;
  height: calc(53vh - 60px);
 
}
// .button-assinar {
//   color: #000;
//   font-weight: "bold";
//   background: #ff9900!important;
//   display: "flex";
//   justify-content: "center";
//   align-items: "center";
//   margin-bottom: "15px";
//   width: '20px';
// }



