


.containerWhite-employ {
  width: 100%;
  box-shadow: 5px 5px 5px black;
  border-radius: 10px;
  background-color: #ebe9e9;
  margin-top: 6%;
 
 
}
.container-cadastro {
  display: flex;
  flex-direction: row;
  margin-bottom: 20px;  
  
  
 
}
.label-saldo {
  background: none;
  width: auto;
}

.container-employers{  
  width: 90%;
  margin: auto;
  margin-top: 80px;
}