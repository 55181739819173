.coding-page {
  padding: 20px;

  font-family: Arial, sans-serif;
  max-width: 800px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 5%;
}

// h2.title {
//   text-align: center;
//   margin-bottom: 20px;
// }

.code-editor {
  border: 1px solid #ccc;
  border-radius: 5px;
  overflow: hidden;
}

.question-container {
  margin-top: 20px;
}

.question-textarea {
  width: 100%;
  padding: 10px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.submit-container {
  margin-top: 20px;
  text-align: center;
}

//   .btn_primary {
//     padding: 10px 20px;
//     font-size: 16px;
//     color: #fff;
//     background-color: #007bff;
//     border: none;
//     border-radius: 5px;
//     cursor: pointer;
//   }

//   .btn_primary:hover {
//     background-color: #0056b3;
//   }

.submit-feedback {
  display: block;
  margin-top: 10px;
  color: green;
}

.coding-page {

  background-color: #f9fafb;
  border: 1px solid #e9e9e9;
  padding: 80px;
}