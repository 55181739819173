

.containerWhiteCredit {
  width: 70%;
  max-width: 120%;
  margin-left: -15rem;
  margin-right: -25%;
  box-shadow: 5px 5px 5px black;
  border-radius: 10px;
  background-color: #ebe9e9;
  position: fixed;
  top: 0;
  margin-top: 5%;
  margin-left: 1%;
  margin-right: auto;
  
 
  
  h2{
    font-weight: bold;
  }
  
}
.NomeEmpresa {
  margin-bottom: 20px;
  text-align: left;
  display: flex;
}

.cancel {
  margin-bottom: 30px;
}
.saldo,
.label-saldo {
  background-color: #fff;
  border-radius: 20px;
  padding: 10px;
  font-size: 1.4em;
  font-weight: bold;
  width: 200px;
}
.container-saldo {
  display: flex;
  flex-direction: row;
  margin-bottom: 20px;
}
.label-saldo {
  background: none;
  width: auto;
}

.container-consumed-credits {
  width: 100%;
  max-width: 1140px;
  margin: 0 auto;
  margin-top: 80px;  
  border-radius: 4px;
border: 1px solid  #E9E9E9;
background: #FCFCFC;
padding: 40px 0;
  
}
.swal-footer {
  display: flex;
  flex-direction: row;
}






