@import url("https://fonts.googleapis.com/css2?family=Righteous&display=swap");
@import "../../shared/colors";
@import "../../shared/global";

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
.container_terms {
  align-items: center;
  padding: 5rem;
  background-color: rgb(248, 250, 250);
}
// .title {
//   margin-top: 60px;
//   font-weight: bold;
//   font-size: 25px;
// }
// p {
//   font-size: 20px;
//   text-align: justify;
//   font-family: sans-serif;
// }
.logo_terms {
  display: flex;
  justify-content: center;
  img {
    flex: 1;
    max-width: 400px;
  }
}
.list {
  font-size: 20px;
  margin-left: 50px;
}
h3 {
  font-weight: bold;
  font-size: 25px;
  text-align: justify;
  margin-top: 50px;
 // margin-left: 50px;
}
.list2 {
  font-weight: bold;
  font-size: 20px;
  text-align: justify;
  margin-top: 30px;
  margin-left: 50px;
}
.button {
  background-color: $button-color !important;
  color: $button-text-color !important;
  padding: 1%;
  width: 35%;
  margin-left: 35%;
}
