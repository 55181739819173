@import "../shared/colors";

body {
  font-size: 16px;
  @extend .bg;
  overflow-x: hidden;
}

.flatlist {
  margin-bottom: 60px;
}

.button-confirm {
  background-color: $button-color !important;
  color: $button-text-color !important;
}

.button-action {
  background-color: $button-action-color !important;
  color: $button-action-text-color !important;
}

.button-cancel {
  background-color: $button-cancel-color !important;
  color: $button-text-color !important;
}

.logo {
  display: flex;
  justify-content: center;

  img {
    flex: 1;
    max-width: 150px;
  }
}

h2 {
  text-align: center;
  font-family: sans-serif;
  font-size: 2rem;
  font-weight: 500;
}

h3 {
  font-family: sans-serif;
}

/*Classes dos textos*/
.title {
  font-size: 1.602rem;
  font-weight: 700;
  color: #413c69;
  text-align: left;
}

.subtitle {
  font-size: 1.424rem;
  font-weight: 700;
  color: #323233;
}

.p_bold,
.btn_tertiary,
.btn_secondary,
.btn_secondary_small,
.btn_primary {
  font-size: 1rem;
  font-weight: 700;
  color: #323233;
}

.p_normal {
  font-size: 1rem;
  font-weight: 400;
  color: #323233;
}

.lbl_bold {
  font-size: 0.79rem;
  font-weight: 700;
  color: #323233;
}

.normal {
  font-size: 0.79rem;
  font-weight: 400;
  color: #323233;
}

.btn_primary {
  padding: 14px 28px;
  color: #413c69;
  background-color: #fcb900;
  border-radius: 4px;
  text-decoration: none;
  text-transform: uppercase;
}

.btn_secondary,
.btn_secondary_small {
  padding: 14px 28px;
  color: #ffffff;
  background-color: #413c69;
  border-radius: 4px;
  text-decoration: none;
  text-transform: uppercase;
}

.btn_tertiary {
  padding: 14px 28px;
  color: #413c69;
  background-color: #ffffff;
  border-radius: 4px;
  border: 1px solid #413c69;
  text-decoration: none;
  text-transform: uppercase;
}

.btn_primary:hover,
.btn_secondary:hover,
.btn_tertiary :hover {
  cursor: pointer;
  filter: brightness(110%);
}

/*# sourceMappingURL=style.css.map */

.space {
  padding: 10px 0;
  width: 100%;
  clear: both;
}

.central-button {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.bg {
  background-color: #f3f4f6 !important;
}

.signin {
  width: 500px;
  min-height: 450px;
  border-radius: 8px;
  border: 1px solid #e9e9e9;
  background: #f5f5f5;

  div.textField {
    margin: 10px 0;
  }
}

.textCenter {
  text-align: center;
}

div.textField {
  width: 100%;
  margin-top: 10px;
}

.parag_bold {
  color: var(--White, #fff);
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
  /* 150% */
}

.breadcrumb-talent {
  display: flex;
  padding: 10px 0;
  max-width: 1140px;
  width: 100%;
  margin: 0 auto;
  height: 50px;
  border-radius: 10px;
  justify-content: space-between;
  align-items: center;
  background-color: #ffffff;

  ol {
    margin-bottom: 0;
  }
}

.css_bread {
  nav {
    width: 100%;
  }

  nav ol {
    list-style-type: none;
    padding: 0;
  }

  nav ol li::after {
    content: "/";
    margin-right: 10px;
    color: #666;
  }

  nav ol li:last-child::after {
    content: "";
    color: #666;
  }

  nav ol li {
    display: inline;
    font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  }

  nav ol li a {
    color: #413c69;
    text-decoration: none;
    margin-right: 10px;
  }

  nav ol li a:hover {
    color: #55304a;
    text-decoration: underline;
  }
}

.input {
  font-family: ubuntu;
  width: 400;
  size: 14px;
  color: #e5e7eb;

  line {
    height: 18px;
  }
}

.btn_secondary_small {
  display: flex;
  font-size: 0.8rem;
  padding: 7px 14px;
  align-items: center;
}

.typography {
  font-weight: 700;
  font-size: 40px;
  line-height: 48px;
  align-items: center;
}

.container-plans {
  display: flex;
  justify-content: center;
  max-width: 1140px;
  width: 100%;
  margin: 0 auto;
  gap: 30px;

  .container-plan {
    display: flex;
    flex-direction: column;
    border-radius: 10px;
    border: 2px solid #dfe4ea;
    padding: 25px 50px;
    width: 100%;
    max-width: 370px;

    .title {
      text-align: left;
      width: 100%;
    }

    .p_normal {
      text-align: left;
      width: 100%;

      span {
        font-size: 42px;
        font-weight: 700;
        color: #403c68;
      }
    }

    hr {
      border: 1px solid #dfe4ea;
    }

    ul {
      text-align: left;
      margin-top: 20px;

      li {
        margin-bottom: 10px;
      }
    }

    .price {
      text-align: center;
      font-weight: bold;
    }

    .container-description {
      flex: 1;
    }
  }
}

.container-cadastro-empresa {
  margin-top: 30px;
  width: 50%;
  justify-content: center;
  text-align: center;
  margin-bottom: 20px;
}

.label-cadastro {
  width: 100%;
  text-align: left;
}

.list-cursos {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  flex-direction: row;
  justify-content: flex-start;

}


// X-Small devices (portrait phones, less than 576px)
@media (max-width: 575.98px) {

  .container1,
  .container-1 {
    padding: 0 10px;
  }

  .container-cadastro-empresa {
    width: 100%;
  }

  #page-courses .textField {
    width: 100%;
  }

  header .header_logo {
    font-size: 16px;
    margin-bottom: 0;
  }

  #page-courses .container-courses {
    padding: 20px;
    margin-bottom: 20px;
  }

  .list-cursos {
    justify-content: center;
  }

  .img-cadastro-aluno {
    margin-top: 45%;
    width: 100%;
  }

  .img-cadastro-empresa {
    margin-bottom: 5%;
    margin-top: 5%;
    width: 100%;
  }

  .btn_primary {
    padding: 10px 7px;
    font-size: 0.7em;

    svg {
      width: 0.7em;
    }
  }

  .btn_secondary,
  .btn_secondary_small {
    padding: 10px 7px;
    font-size: 0.7em;

    svg {
      width: 0.7em;
    }
  }

  .btn_tertiary {
    padding: 7px 10px;
    font-size: 0.7em;

    svg {
      width: 0.7em;
    }
  }

  .player-buttons {
    margin-left: 20px;
  }

  // container

  .container-player {
    display: flex;
    justify-content: center;
    /* centraliza horizontalmente */
    align-items: center;
    /* centraliza verticalmente */
    max-width: 100%;
    /* para garantir que o vídeo não ultrapasse a largura do contêiner */
    height: auto;
    /* para garantir que a altura seja ajustada automaticamente */
  }

  //tela de My-skills

  .my-skills-container {
    padding: 10px;
  }

  .my-skills-container .containerWhite {
    overflow-x: auto;
  }

  .my-skills-container .my-skills thead {
    display: none;
  }

  .my-skills-container .my-skills tbody tr {
    display: block;
    margin-bottom: 20px;
    border: 2px solid #ddd; // Adicionei uma borda para diferenciar com as linhas
    padding: 10px; // Ajuste conforme necessário
  }

  .my-skills-container .my-skills tbody td {
    display: block;
    text-align: left;
    // border: none;
  }

  // essa Tela de list-interviews

  .my-interviews-container {
    padding: 10px; // Ajuste conforme necessário
  }

  .my-interviews-container .containerWhiteII {
    overflow-x: auto;
    margin-top: 30px;
  }

  .my-interviews-container .title {
    display: none;
  }

  .my-interviews-container .my-interviews thead {
    display: none;
  }

  .my-interviews-container .my-interviews tbody tr {
    display: block;
    margin-bottom: 10px;
    border: 1px solid #ddd;
    padding: 10px;
  }

  .my-interviews-container .my-interviews tbody td {
    display: block;
    text-align: left;
    width: 100%;
    border: none; //aqui foi removido as bordas, dentro do td
  }

  .my-interviews-container .my-interviews tbody td:last-child {
    text-align: center; // botão centralizado
    margin-top: 10px; // Adiciona margem ao botão
  }

  .my-interviews-container .my-interviews tbody td button {
    width: 100%; // Ocupa toda a largura disponível na tela
  }

  #page-courses {
    margin-top: 0;
  }

  .list-cursos {
    margin-bottom: 50px;
  }

  .responder {
    display: flex;
    margin: 0px 0px 0px 0px;
    margin-left: 10%;

  }

  .icon {
    margin-left: 0px 0px 0px 0px;
  }






}