@import url("https://fonts.googleapis.com/css2?family=Righteous&display=swap");
@import "../../shared/colors";
@import "../../shared/global";
// @media only screen and (max-device-width: 900px) {
//   /* .menu { width:600px; } */
  
//   .container-1 {
   
//     width: 600;
//     display: flex;
//   }
// }

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  
}
.img-cadastro-empresa{
  margin-bottom: 5%;
  margin-top: 5%;
  
}

// .container-1 {
//   // height: 100vh;
//   display: flex;
//   align-items: center;
//   justify-content: center;
//   height: 100%;

// }

// .signin {
//   width: 500px;
//   min-height: 450px;
//   border-radius: 3px;
//   box-shadow: 3px 3px 5px #001324dc;
// }

.signin_wrapper {
  background-color: #ebe9e9;
}

.signin_success {
  background: #005baa;
}
.signin_success h1 {
  letter-spacing: 1px;
}
.signin_success p {
  margin-top: 15px;
  letter-spacing: 1px;
}

form {
  padding: 20px 50px;

  top: 0;
  padding: 20px 5%; 
 
 
  margin: 0 auto; 
}


// p {
//   text-align: center;
//   font-family: Branding SF Black;
//   font-size: 2rem;
//   font-weight: 500;
//   margin: 25px;
// }


div.textField input {
  font-size: 20px;
}
div.textField svg {
  font-size: 25px;
}

.error_msg {
  color: #ff0000e0;
  font-size: 17px;
  font-weight: 700;
  margin: 0px;
  padding: 0px;
  text-align: center;
}

span.signup {
  color: #f9eacf;
  cursor: pointer;
}
span.signup2 {
  color: #adc8f0;
  cursor: pointer;
}

.spacial {
  background: orange;
  border-radius: 5px;
  color: #f9eacf;
  margin-top: 5rem;
  margin: absolute;
}

h3 {
  text-align: center;
  margin-top: 15px;
}

.check-box {
  margin-bottom: 30px;
}

.cancel {
  margin-top: 4%;
}

.submit-form {
  display: flex;
  top: 50%;
  left: 50%;
 //position: absolute;
  transform: translate(-50%, -50%);
  text-align: center;
  color: "#ffffff";
  font-size: 25px;
  font-weight: bold;
  text-shadow: 3px 3px 3px #000;
}
.estado {
  text-align: left;
}
// .logo-animado {
//   background-color: #ffcc00;
//   border-radius: 10px;
//   margin-bottom: 20px;
//   animation: logo-animado 1s linear infinite;
// }
// @keyframes logo-animado {
//   0% {
//     opacity: 1;
//   }
//   50% {
//     opacity: 0.2;
//   }
//   100% {
//     opacity: 1;
//   }

