
.body{
    overflow-x: hidden!important; 
    margin-right: 0;
    margin-left: 0;
    padding-right:0;
}
.button-confirm{

    border-radius: 10px;
    padding: 0px;
    
}
.habilidades {
    width: 80%;
   
}
.table-modal-skill {
    justify-content: center;
    display: flex;
    flex-direction: column;
    width: 100%;
  
}

.MuiButtonBase-root.show-skills{
    padding: 5px;
    border-radius: 5px;
}

.title-modal{
    font-weight: bold;
    flex: 1;
    flex-grow: 5;
}

.container-title{
    display: flex;
    flex-direction: row;
   

}

.MuiButtonBase-root.button-close{
    width: 80px;
    align-self: flex-end;
    background-color: #ccc;
    padding: 5px;
}

