.containerWhite {
  align-self: center;
  width: 100%;   
  max-width: 1140px;
  display: flex;
  flex-direction: column;
  align-items: center; 
}

.edit-profile-container{

  width: 100%;
  box-shadow: 5px 5px 5px black;
  border-radius: 10px;
  flex-direction: "column",
 
 
}
.container-cadastro {
  display: flex;
  flex-direction: row;
  margin-bottom: 20px;
}
.label-saldo {
  background: none;
  width: auto;
}

.form {
  width: 100%;
}

.container-s {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.skill,
.label-skill {
  background-color: #fff;
  border-radius: 20px;
  padding: 2px;
  font-size: 1.4em;
  font-weight: bold;
  width: 40px;
  margin-left: 5px;
}
.container-skill {
  display: flex;
  flex-direction: row;
  margin-bottom: 20px;
}
.label-skill {
  background: none;
  width: auto;
}
.person{
  background-color: #ededed;  
}

.my-skills-container{ 
  width: 100%;
  display: flex;
  flex-direction: column;
  height: calc(90vh - 100px);
  
}

.my-skills{
  box-shadow: 5px 5px 5px #E5E7EB;
  border-radius: 10px 10px 0px 0px;

  th, td{
    padding: 20px;
  }

  th{
    color: #413C69;
    text-align: left!important;
  }

  td{
    color: #323233;
  }

  thead tr{
    background-color: #E5E7EB;
  }
  .custom-background {
    background-color: #e5e7eb !important;
  }
  
  
}