.topo {
  display: flex;

  flex-direction: row;
  margin-top: 20px;
  align-items: baseline;
  .titulo-pagina {
    width: 20%;
  }
}
main {
  display: flex;

  flex-direction: column;
  
}
.watch {
  display: flex;
  flex-direction: row;
  margin-bottom: 15px;
  margin-left: 15px;
  margin-right: 30px;
  padding-bottom: 15px;
  background: #ededed;
  border-radius: 10px;
}

.container-texto {
  flex: 5;
  display: flex;
  flex-direction: column;
  margin-left: 30px;
  cursor: pointer;
}

.barraEvol {
  text-align: center;

  height: 20px;
  display: flex;
  align-items: center;
  width: 900px;
}

// .barra {
//   background: rgb(206, 98, 98);
//   width: 900px;
//   width: 50;
// }
input {
  display: flex;
  background: #cccc;
  border-radius: 10px;
  width: 85%;
  margin-right: 10px;
  margin-left: 10px;
  cursor: pointer;
}
.logo img {
  width: 100px;
  margin: 20px;
  display: flex;
}

.pesquisa-icone {
  display: flex;
  font-size: 3em;
  align-self: center;
  margin-top: 10px;
  margin-right: 50px;
}
.pesquisa {
  display: flex;
  cursor: pointer;
  font-size: 1.2em;
  padding: 5px;
  margin-right: 80px;
}
.titulo {
  text-align: left;
  margin-top: 2%;
  h2{
    text-align: left;
    font-size: 1.2em!important;
  }
}

.progresso {
  margin-right: 5%;
}
.btcIniciar {
  width: 20%;
  align-self: center;
  margin-top: 20px;
}
.btcContinuar {
  width: 20%;
  align-self: center;
  margin-top: 20px;
  margin-left: 20px;
}
.btcContainer {
  display: flex;
  flex-direction: row;
  align-items: right;
  justify-content: end;
  margin-right: 80px;
  margin-left: 80px;
}

@media (max-width: 600px) {
  .watch,
  .topo,
  .h2,
  .container-texto,
  .pesquisa,
  .header,
  .siderbar,
  input,
  .sidebar__menu__item__text {
    width: 100%;
  }
  .logo img {
    display: none;
  }
}

.start-course {
  background-color: blueviolet !important;
  color: #fff !important;
}


.continue-button {
  width: 20%;
  align-self: end;
  margin-top: 20px;
  margin-right: 45px;
}
.topo{
  
  width: 20%;
  margin-top: 3%;   
 
}



