@import '../../shared/colors';


.sidebar{
    height: calc(100vh - 60px);
    width: 100%;
    background-color: rgb(235, 233, 233);
    overflow: auto;
   
    &__logo{
        display: grid;
        place-items: center;
        height: 120px;
        font-size: 1.5rem;
        font-weight: 700;
       
    }

    &__menu{
        position: relative;
      
        &__item{
            display: flex;
            align-items: center;
            place-content: flex-start;
            padding: 1rem 3rem;
            font-size: 1.25rem;
            font-weight: 500;
            color: #555;
            transition: color 0.3s ease-in-out;
            text-decoration: none;
          
           
            
            &.active{
                color: black;
                // background-color: #308efe;
                margin-right: 1rem;
                margin-left: 1rem;
                
            }
            &__icon{
                margin-right: 0rem;
                i{
                    font-size: 1.75rem;
                }               
            }
            &__text{
                text-decoration: none;                
                font-size: 14px;
                
               
            }
        }

        &__indicator{
            position: absolute;
            top: 0;
            left: 50%;
            width: calc(100% - 40px);
            border-radius: 10px;
            background-color: #555;
            z-index: -1;
            transform: translateX(-50%);
            transition: 0.3s ease-in-out;
        }
       
    }

    .logo {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 20px;    
    }
}

.play_icon {
    font-size: 20px;
    width: 100%;
    margin-left: -70%;
    color: goldenrod;
}

a:link {
  text-decoration: none;
  color: $link-color;
}

// a:visited {
//   text-decoration: none;
// }

a:hover {
  text-decoration: underline;

}

a:active {
  text-decoration: underline;
}

.aula {
    font-size: 20px;
    width: 100%;
    margin-left: -250%;
    
}

::-webkit-scrollbar-thumb{
    background-color:black;
   
    border-top-right-radius:5px ;
}
/* Defina uma classe para a animação */
.item-clicked {
    animation: background-color-animation 0.3s ease;
  }
  
  /* Defina a animação */
  @keyframes background-color-animation {
    0% {
      background-color: inherit;
    }
    100% {
      background-color: rgba(0, 128, 0, 0.5); /* Verde transparente */
    }
  }

  
  