#page-courses {
  margin-top: 80px;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  flex: 1;

  .topo-curso {
    display: flex;
    text-align: center;
    flex-direction: row;
    margin-top: 20px;
    // align-items: baseline;
    align-items: center;

    .titulo-pagina {
      width: 20%;
    }
  }

  .watch {
    display: flex;
    flex-direction: row;
    margin-bottom: 15px;
    margin-left: 15px;
    margin-right: 30px;
    padding-bottom: 15px;
    background: #ededed;
    //border-radius: 10px;
  }

  .container-texto {
    flex: 5;
    display: flex;
    flex-direction: column;
    margin-left: 30px;
    cursor: pointer;
  }

  .barraEvol {
    text-align: center;

    height: 20px;
    display: flex;
    align-items: center;
    width: 900px;
  }

  .logo img {
    width: 100px;
    margin: 20px;
    display: flex;
  }

  .pesquisa-icone {
    display: flex;
    font-size: 3em;
    align-self: center;
    margin-top: 10px;
    margin-right: 50px;
    text-align: center;
  }

  .pesquisa {
    display: flex;
    cursor: pointer;
    font-size: 1.2em;
    padding: 5px;
    margin-right: 80px;
    margin-top: 55px;
  }

  // .titulo {
  //   text-align: left;
  //   margin-top: 2%;

  // }

  .progresso {
    margin-right: 5%;
  }

  .btcIniciar {
    width: 20%;
    align-self: center;
    margin-top: 20px;
  }

  .btcContinuar {
    width: 20%;
    align-self: center;
    margin-top: 20px;
    margin-left: 20px;
  }

  .btcContainer {
    display: flex;
    flex-direction: row;
    align-items: right;
    justify-content: end;
    margin-right: 80px;
    margin-left: 80px;
  }

  // @media (max-width: 600px) {
  //   .watch,
  //   .topo,
  //   .h2,
  //   .container-texto,
  //   .pesquisa,
  //   .header,
  //   .siderbar,
  //   input,
  //   .sidebar__menu__item__text {
  //     width: 100%;
  //   }
  //   .logo img {
  //     display: none;
  //   }
  // }

  .start-button {
    height: 100px;
  }

  .start-course {
    background-color: blueviolet !important;
    color: #fff !important;
  }

  // .continue-button {


  //   margin-top: 20px;
  //   margin-right: 45px;
  //   display: 'flex';
  //   justify-content: 'center';
  //   align-items: 'center';
  // }
  .MuiButton-endIcon {
    margin: auto;

  }

  .container-courses {
    display: flex;
    flex-direction: column;
    background-color: #f9fafb;
    border: 1px solid #e9e9e9;
    padding: 40px;
  }

  .textField {
    display: flex;
    flex-direction: column;
    margin: 10px;
  }

  .container-search {
    display: flex;
    flex-direction: row;
    justify-content: center;

  }

  .MuiFormControl-root {
    width: 80%;
  }

  .confirmButtonColor {
    color: black;
  }






}

/* No seu arquivo CSS ou no <style> da página */
.swal2-confirm {
  // padding: 10px 20px!important; /* Aumente os valores conforme necessário */
  font-size: 16px !important;
  /* Aumente o tamanho da fonte conforme necessário */

}

.swal2-cancel {
  //padding: 10px 20px; /* Aumente os valores conforme necessário */
  font-size: 16px;
  /* Aumente o tamanho da fonte conforme necessário */
}

.trilha-button{

   display: flex;
  justify-content: center;
  max-width: 100%;


}

.trilha-content{

  padding:50px!important;
}

