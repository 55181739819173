@import url("https://fonts.googleapis.com/css2?family=Righteous&display=swap");
@import "../../shared/colors";
@import "../../shared/global";

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.container1 {
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.signin_success {
  background: #005baa;
}
.signin_success h1 {
  letter-spacing: 1px;
}
.signin_success p {
  margin-top: 15px;
  letter-spacing: 1px;
}

form {
  padding: 20px 50px;
}

.logo {
  display: flex;
  justify-content: center;
  // img {
  //   flex: 1;
  //   width: 150px;
  //   font-size: 20%;
  // }
}

h2 {
  text-align: center;
  font-family: sans-serif;
  font-size: 1.4rem !important;
  font-weight: 500;
}

div.textField input {
  font-size: 20px;
}
div.textField svg {
  font-size: 25px;
}

button {
  //background: $button-color;
  width: "auto";
  //line-height: 35px;
  //margin: 20px 9px;
  //color: white;
  //font-size: 20px;
  font-weight: bold;
  //padding: 5px;
  border: none;
  //border-radius: 5px;
  cursor: pointer;
}
.full {
  width: 100%;
}

button:hover {
  background: #413c69;
  color: #fff;
}
.error_msg {
  color: #ff0000e0;
  font-size: 17px;
  font-weight: 700;
  margin: 0px;
  padding: 0px;
  text-align: center;
}

span.signup {
  color: #f9eacf;
  cursor: pointer;
}

.forget-password {
  text-align: right;
  margin-bottom: 30px;
  margin-top: 10px;
  align-self: flex-end;
}

.submit-form {
  top: 50%;
  left: 50%;
  position: absolute;
  transform: translate(-50%, -50%);
  text-align: center;
  color: "#ffffff";
  font-size: 25px;
  font-weight: bold;
  text-shadow: 3px 3px 3px #000;
}
.company {
  margin-top: 15px;
}
// .button-company {
//   margin-left: 10% !important;

//   width: 80% !important;
//   height: 50px !important;
//   background: #ffffff !important;
//   color: black !important;
//   border: 1px solid black !important;
//   font-weight: bold !important;
//   text-align: center !important;
//   box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.5) !important;
//   &:hover {
//     border-color: #ffcc00 !important; // Altera a cor da borda ao passar o mouse sobre o botão
//   }
// }
// .button-student {
//   margin-left: 10% !important;
//   width: 80% !important;
//   height: 50px !important;
//   box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.5) !important;
//   background: #ffffff !important;
//   color: black !important;
//   border: 1px solid black !important;
//   font-weight: bold !important;
//   &:hover {
//     border-color: #ffcc00 !important; // Altera a cor da borda ao passar o mouse sobre o botão
//   }
// }

.login_talent[type="text"] {
  display: flex;
  margin: 0 0;
  align-items: center;
  justify-content: center;
  border: 2px solid #ccc;
  width: 100%;
  padding: 15px;
  border-radius: 5px;
  margin-bottom: 20px;
  background: #ffffff;
  outline: none;
  color: 'rgba(0, 0, 0, 0.1)';
  transition: border-color 0.3s, border-width 0.3s;
}

.login_talent[type="text"]:hover {
  border-color: black; /* cor da borda quando hover */
  border-width: 1px; /* espessura da borda quando hover */
}

.login_talent[type="text"]:focus {
  border-color: blue; /* cor da borda quando em foco */
  border-width: 2px; /* espessura da borda quando em foco */
}
