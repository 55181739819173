// .container-classes{
//   display: flex;
//   flex-direction: column;
//   width: 100%;
//   justify-content: flex-start;
//   align-items: flex-start;
// }

// .containerWhite-classes {
//   flex: 1;
//   box-shadow: 5px 5px 5px black;
//   border-radius: 10px;
// }
// .classes, .label-classes {
//   background-color: #fff;
//   border-radius: 20px;
//   padding: 10px;
//   font-size: 1.4em;
//   font-weight: bold;  
// }
// .label-classes {
//   background: none;
//   width: auto;
// }
// .table{
//   margin: 20px;
//   width: 90%;
//   th, td.center{
//     text-align: center;
//   }
// }
.list-class{
  display:flex;
  margin-top: '20%';
  margin-left: '20%';
}

