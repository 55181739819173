.course-image {
  position: relative;
  // height: 200px;
  overflow: hidden;
  img {
    margin-top: 10px;
    object-fit: cover;
    width: 100%;
    height: 100%;
    padding: 20px;
  }
}

.course-number {
  position: absolute;
  top: 0px;
  left: 0px;
 
  color: rgb(10, 10, 10);
  font-weight: bold;
  border-radius: 50%;
  width: 60px;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.2em;
  
}

.card-title {
  margin-top: 15px;
  font-size: 1.1em;
  
}


.card-body {
  padding: 70px; /* Aumenta o padding dentro do card */
}

.btn-primary {
  background-color: #6f42c1;
  border: none;
}

.btn-warning {
  background-color: #ffc107;
  border: none;
}

.btn {
  font-size: 1em;
  padding: 10px 0;
}

.courses-container {
  padding: 50px;
  background-color: #F9FAFB;
  border-radius: 8px; /* Opcional, mas pode ajudar a suavizar as bordas */
}


