


.quizQuestion {
  flex-direction: row;
  width: 70%;
}

.menu {
  display: flex;
  flex-direction: row;
  margin-top: 10px;
  margin-left: 20px;
  justify-content: space-between;

  .my-courses {
    flex: 1;
    width: 90%;
    margin-right: 20px;
  }

  .previous-lesson {
    flex: 1;
    max-width: 220px;
    margin-right: 20px;
  }

  .next-lesson {
    flex: 1;
    max-width: 220px;
  }

  .all-lessons {
    flex: 1;
    width: 90%;
    margin-right: 20px;
  }

  .answer-questions {
    flex: 1;
    margin-right: 20px;
  }
}

.logo_git {
  font-size: 35px;
  margin-top: -2px;
  margin-left: 56%;
  cursor: pointer;
  transition-duration: 0.4s;
}

.logo_git:hover {
  opacity: 0.6;
}

.button {
  background-color: darkorange;
  width: 18%;
  height: 50%;
  line-height: 16px;
  margin: 15px 9px;
  color: white;
  font-size: 17px;
  padding: 5px;
  border: none;
  border-radius: 5px !important;
  text-align: center;
  cursor: pointer;
  transition-duration: 0.4s;
}

.button:hover {
  background-color: rgb(252, 181, 49);
  color: white;
}
.videoAula {
  display: flex;
  justify-content: 'center';
  align-items: center;
  text-align: center;
  justify-content: space-between;
  flex-direction: column;
}
.ReactvideoPlayer {
  display: flex;
 // margin-bottom: 10%;
 // width: 100px;
 justify-content: 'center';
 align-items: center;
 
}
.description {
 
  margin-bottom: 10px;
  width: 100%;

  p {
    
    text-align: justify;
    font-size: 1.2em;
  }
}

.resposta {
  h6 {
    margin-top: 20px;
    margin-left: 12%;
    display: flex;
    text-align: justify;
  }
}

.textResposta {
  white-space: pre-line;
  padding: 0;
  margin-left: 9%;
  p {
    margin-top: 0%;
    font-size: 15px;
    color: #838181;
  }
}
.textArea {
  height: 200px;
  width: 96%;
  text-align: justify;
  font-family: sans-serif;
}
.textArea2 {
  height: 150px;
  width: 96%;
  text-align: justify;
  font-family: sans-serif;
 
}
.Doubt {
 // background-color: #fc0;
  margin-bottom: 10px;
  border-radius: 5px;
  h3 {
    padding: 10px;
    font-size: 1.3em;
  }
}
.descriptionDoubt {
  display: flex;
  left: 100%;
  margin-top: -30px;

  h3 {
    font-size: 1.2em;
  }
}
.addDoubt {
  display: flex;
  align-items: center;
  margin-left: 40px;
  .icon {
    font-size: 25px;
    margin-right: 10px;
    margin-top: -10px;
  }
  h4 {
    font-size: 1.2em;
  }
}


.perguta-enviada ul {

 background-color:#413c69;
}
.css-1yo8bqd{
  padding: 0!important;
}


.textField {
  font-size: 1.2em;
}

.textoPergunta {
  display: flex;
  margin-top: 50px;
}
.respostaAluno {
  border-radius: 10px;
  display: flex;
  margin-left: 50px;

  .iconAluno {
    font-size: 60px;
    margin-left: 30px;
    display: flex;
    margin-top: 10px;
    img {
      width: 80px;
    }
  }
}

.nomeAluno {
  display: flex;
  margin-left: 80px;
 
}

.responder {
  display: flex;
  margin-top: 20px;
  left: 100px;
  margin-left: 25px;

  align-items: center;
  display: inline-block;
}
.BsArrw {
  display: flex;
  font-size: 50px;
  margin-top: 10px;
  float: right;
  margin-right: 10px;
}
#descreva-duvidas{
padding: 0;
}
.divider {
  margin-left: 1%;
  margin-right: -5%;
  padding: 0px;
  
}
.like-dislaike {
  flex-direction: row;
  display: flex;
  margin-right: 10px;
  align-items: center;
  display: inline-block;
}
.comment-div {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

.name-text {
  display: flex;
  flex-direction: row;
  display: inline-block;
  margin-left: 30px;
  margin-top: 20px;
  padding: 0;
}
.like-dislaike {
  display: flex;
  flex-direction: row;
  width: 50px;
  margin-left: 15px;
 
}
.button-cancelar{
  width: '80px'!important;
  height: '50px';
  padding: '14px  28px';
  color: '#413C69';
  border-radius: '4px';
  border: '1px solid #413C69';
 // box-shadow: '0 3px 5px rgba(0, 0, 0, 0.5)';
  background-color: '#ffffff';
  text-decoration: 'none';
  text-transform: 'uppercase';

}
// .form-comments{
//   background-color: #FFFFFF;
// }

nav{
  width: 100%;
  margin-left: 10px;
}
nav ol{
  list-style-type: none;
  padding: 0;
}
nav ol li::after{
  content: "/"; 
  margin-right: 10px; 
  color:#666;
}

nav ol li:last-child::after{
  content: ""; 
  color:#666;
 
}

nav ol li{
  display: inline;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;    
}

nav ol li a {
  color:#413c69;
  text-decoration: none;    
  margin-right: 10px;
}

nav ol li a:hover {
  color:#55304a;
  text-decoration: underline;
  
}

.videoAula{
  .subtitle{
    text-align: left;
    align-self: flex-start;
  }
}

.container-button-all-lessons{
  display: flex;
  justify-content: flex-end;
  margin-right: 20px;
}

.container-player{
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
}
.player-buttons{
  width: 100%;
  max-width:1140px;
  margin: 0 auto;
  padding: 20px;
  background:#F9FAFB;
  border: 1px solid #E9E9E9;
}
.responder{
  display: flex;
  margin:0;
  margin-left:83%;  
}