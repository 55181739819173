

.container {
  display: flex;
  width: 900px;
  overflow: hidden!important;
}
.button-cancel-recover{
  margin-left:10%!important;
  margin-bottom: 10%!important;
  margin-top: 10%!important;
 margin-right: 25px!important;
  width: 70%!important; 
  height: 50px!important;
  background:#ffffff!important;
  color:black!important;
  border: 1px solid black!important;
   font-weight: bold!important;
 text-align: center!important;
 box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.5)!important;
  &:hover {
    border-color: #ffcc00!important; // Altera a cor da borda ao passar o mouse sobre o botão
  }
}
.swal-button--confirm{
  background-color: #ffcc00;
 height: 50px;
 display: 'flex';
 width: 200px;
 color:black;
 text-align: center;
 margin-bottom: 10%;
  
}
.swal-footer{
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center
}
.swal-text{
  text-align: center;
  
}
.swal-modal{
  border-radius: 5%;
}

