$button-color: #0f65e5;
$button-text-color: #fff;
$button-cancel-color: rgb(235, 37, 37);
$button-color-hover: #0f00e5;
$background-page-color: #fff;

$button-action-color: #f90;
$button-action-text-color: #fff;

$button-padding: 15px;

$link-color: #000;