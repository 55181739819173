.input-link-youtube {
  display: flex;
  margin: 0 0;
  align-items: center;
  justify-content: center;
  border: 2px solid #ccc;
  width: 100%;
  padding: 6px;
  border-radius: 5px;
  margin-bottom: 20px;
  background: #ffffff;
  outline: none;
  color: 'rgba(0, 0, 0, 0.1)';
  transition: border-color 0.3s, border-width 0.3s;
}

.input-link-youtube:hover {
  border-color: black;
}

.input-link-youtube:focus {
  border-color: rgb(110, 110, 255);
  border-width: 2px;
}

.container-profile {
  display: flex;
  flex-direction: column;
  background-color: #f9fafb;
  border: 1px solid #e9e9e9;
  padding: 10px -10px 10px -10px;
  width: 100%;
}

.title-left {
  display: flex;
  justify-content: left;
}

.mlinputcontainer {
  width: 100%;
}

#buttonEditar {
  width: 40vh;
}

.form-dados {
  width: 100%;
  height: auto;
  background-color: #f9fafb;
}

input {
  margin-left: 0px;
}

.label-dados {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  width: 100%;
}

#round-button-trash {
  width: 12vh;
  height: 5vh;
  //rounded
  border-radius: 20px;
}

//hover red
#round-button-trash:hover {
  background-color: #ff0000;
}

.custom-image img {
  width: 200px;
  height: 200px;
  object-fit: cover;
  /* Define como a imagem deve ser redimensionada para preencher o contêiner */
}

.custom-image-buttons {
  display: flex;
  justify-content: center;
  align-items: center;

  button {
    margin-left: 8px;
  }
}
