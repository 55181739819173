@import url("https://fonts.googleapis.com/css2?family=Righteous&display=swap");
@import "../../shared/colors";
@import "../../shared/global";
@media only screen and (max-device-width: 900px) {
  /* .menu { width:600px; } */
 
   
 .container {width: 600;}
 

  }

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.container {
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;

}

// .signin {
//   width: 500px;
//   min-height: 450px;
//   border-radius: 3px;
  
// }



.signin_success {
  background: #005baa;
}
.signin_success h1 {
  letter-spacing: 1px;
}
.signin_success p {
  margin-top: 15px;
  letter-spacing: 1px;
}

form {

  //padding: 20px 50px;
  background-color: #ffffff;
  
}

// p {
//   text-align: center;
//   font-family: Branding SF Black;
//   font-size: 2rem;
//   font-weight: 500;
//   margin: 25px;
// }


div.textField input {
  font-size: 20px;
}
div.textField svg {
  font-size: 25px;
}

.error_msg {
  color: #ff0000e0;
  font-size: 17px;
  font-weight: 700;
  margin: 0px;
  padding: 0px;
  text-align: center;
}

span.signup {
  color:#f9eacf;
  cursor: pointer;
}
span.signup2 {
   color:#adc8f0;
   cursor: pointer;    
}

.spacial {
  background: orange;  
  border-radius: 5px;
  color:#f9eacf;
  margin-top: 5rem;
  margin: absolute;
  
}

h3 {
  text-align: center;
  margin-top: 15px;
  
}

.check-box{
  margin-bottom: 30px;
}

.cancel {
  margin-top: 4%;
}

.submit-form{
  top: 50%;
  left: 50%;
  position: absolute;
  transform: translate(-50%, -50%);
  text-align: center;
  color: '#ffffff';
  font-size: 25px;
  font-weight: bold;
  text-shadow: 3px 3px 3px #000;
}
.estado {

  text-align: left;
  
}