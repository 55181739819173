header {
    display: flex;
    flex-direction: row;
    background-color: #413c69;
    // padding: 1% 2%;
    height: 60px;
    //position: fixed;
    // z-index: 1000;
    width: 100%;
    align-items: center;
    // position: fixed;
    padding-right: 30px !important;

    a {
        color: rgba(255, 255, 255, 1);
        display: flex;
        align-items: center;

        img {
            margin-left: 10px;
        }
    }

    a:hover {
        text-decoration: none;
        color: rgba(255, 255, 255, 1);
    }

    .options {
        display: flex;
        flex-direction: row;
        flex-grow: 2;
        justify-content: flex-end;
        align-items: center;

        div,
        h6,
        img {
            margin-left: 10px;
        }

        img {
            margin-right: 10px;
        }
    }
}

header .header_logo {
    padding-left: 10px;



}

.logout {
    display: flex;

    border-radius: 10px;
    width: 10%;
    margin-right: 10px;
    margin-left: 10px;

}

.link-company {
    color: #202020 !important;
}

.usuario {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    margin-right: 10px;
}
@keyframes bounce {
    0%, 100% {
      transform: translateY(0);
    }
    50% {
      transform: translateY(-10px);
    }
  }
  
  .icon-bounce {
    animation: bounce 1s infinite;
  }
  